import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../state/api';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export const URL_ACCOUNT_ID_KEY = 'id';
const ACCOUNTS_STALE_TIME = 60 * 1000;
const REFETCH_INTERVAL = 5_000;

type TAccountQueryProps = {
  enabled?: boolean;
  isPollingEnabled?: boolean;
};

export const useAccountsQuery = ({
  enabled = true,
  isPollingEnabled,
}: TAccountQueryProps = {}) => {
  const api = useApi();
  const client = useQueryClient();
  return useQuery(['accounts'], async () => await api.getAccounts(), {
    staleTime: ACCOUNTS_STALE_TIME,
    suspense: true,
    enabled,
    structuralSharing: false,
    refetchInterval: isPollingEnabled && REFETCH_INTERVAL,
    onSuccess: () =>
      isPollingEnabled && client.invalidateQueries(['transfers', 'list']),
  });
};

export const useAccountQuery = (accountId: string) => {
  const api = useApi();
  return useQuery(['accounts', accountId], () => api.getAccount(accountId));
};

export const useSetAccountIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (accountId?: string) => {
      accountId
        ? params.set(URL_ACCOUNT_ID_KEY, accountId)
        : params.delete(URL_ACCOUNT_ID_KEY);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useAccountIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(URL_ACCOUNT_ID_KEY);
};

export const useAccountByUrl = () => {
  const [params] = useSearchParams();
  const id = params.get(URL_ACCOUNT_ID_KEY);
  const { data: accounts } = useAccountsQuery();

  return useMemo(() => {
    return accounts?.find((account) => account.id === id);
  }, [id, accounts]);
};

export const useAccountCurrencyQuery = (accountIdentifier: string) => {
  const api = useApi();

  return useQuery(
    ['account', 'currency', accountIdentifier],
    () => api.getAccountCurrency(accountIdentifier),
    {
      enabled: accountIdentifier.length >= 14 && accountIdentifier.length <= 34,
      retry: false,
      staleTime: ACCOUNTS_STALE_TIME,
    },
  );
};
