import { Button, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import {
  BankModalBody,
  BankModalFooter,
} from '../../../components/BankModal/BankModal';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import {
  TTransferDetailsForm,
  useTransferWizardContext,
} from '../TransferWizard';
import { DetailsCard } from './TransferDetailsForm';
import { getCurrencyIcon } from '../../../currency';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import { TransferWizardAmountField } from './TransferDetailsForm';
import ExchangeDropdownField from '../../../components/ExchangeDropdown/ExchangeDropdownField';
import { TExchangeDropdownOption } from '../../../components/ExchangeDropdown/ExchangeDropdownOption';
import { useAccountsQuery } from '../../../hooks/accounts/queries';
import { useCallback, useEffect, useMemo } from 'react';
import { ThinDivider } from '@payler/ui-components';
import { ChevronCircledIcon } from '../../../icons';
import {
  TCreateOutgoingTransferDto,
  TFeeBaasProvider,
} from '@payler/api/client-office';
import { useFormContext } from 'react-hook-form';
import { useToasts } from '@payler/bank-utils';
import { isEmpty } from 'lodash';
import { useTransferFeeQuery } from '../../../hooks/transfers/queries';

export const TransferDetailsSelf = () => {
  const {
    initialStep,
    setStep,
    setTransferDetails,
    initialSenderAccount,
    senderAccount,
    setTransfer,
    receiverAccount,
    setReceiverAccount,
    setTransfersFee,
  } = useTransferWizardContext();

  const { t } = useTranslation();
  const { formatAmountByCurrency } = useLanguageFeatures();
  const { data: accounts } = useAccountsQuery({
    enabled: !initialSenderAccount,
  });
  const toasts = useToasts();
  const methods = useFormContext<TTransferDetailsForm>();
  const {
    formState: { errors },
    watch,
  } = methods;
  const amount = watch('amount');

  const { data: fee, isFetching } = useTransferFeeQuery({
    BaasProvider: senderAccount?.baasProvider.shortName as TFeeBaasProvider,
    TransferType: 'outgoing',
    Amount: amount,
    System: 'self',
    Currency: senderAccount?.currency,
  });

  useEffect(() => {
    setTransfersFee(fee);
  }, [fee, setTransfersFee]);

  const receiverAccountOptions: TExchangeDropdownOption[] = useMemo(() => {
    if (!accounts) return [];
    return accounts?.reduce((accum, account) => {
      if (
        account.currency === senderAccount?.currency &&
        account.id !== senderAccount.id
      ) {
        accum.push({
          label: formatAmountByCurrency(account.amount, account.currency),
          value: account.id,
          caption: account.name,
          icon: getCurrencyIcon(account.currency),
        });
      }
      return accum;
    }, [] as TExchangeDropdownOption[]);
  }, [
    accounts,
    formatAmountByCurrency,
    senderAccount?.currency,
    senderAccount?.id,
  ]);

  const handleSubmit = methods.handleSubmit((values) => {
    if (!senderAccount) {
      toasts.error(t('accounts:transferMoney.accountNotFound'));
      return;
    }

    if (!receiverAccount) {
      toasts.error(t('accounts:transferMoney.recipientAccountNotFound'));
      return;
    }

    const transfer: TCreateOutgoingTransferDto = {
      senderAccountId: senderAccount.id,
      sendingAmount: values?.amount ? Number(values.amount) : 0,
      system: 'self',
      recipient: {
        account: {
          accountIdentifier: receiverAccount.number,
          currency: receiverAccount.currency.toUpperCase(),
        },
        isVisible: false,
        recipientType: 'self',
      },
    };
    setTransfer(transfer);
    setTransferDetails(values);
    setStep('info');
  });

  const onAccountChange = useCallback(
    (accountId: string) => {
      const selectedAccount = accounts?.find(
        (account) => account.id === accountId,
      );
      setReceiverAccount(selectedAccount);
    },
    [accounts, setReceiverAccount],
  );

  return (
    <>
      <BankModalBody>
        <VStack
          id="selfTransferDetails"
          spacing={2}
          height="100%"
          flex="1"
          alignItems="stretch"
          as="form"
          onSubmit={handleSubmit}
        >
          <Text textStyle={TextStyles.Subtitle14Regular}>
            {t('accounts:transferMoney.transferDetailsDescription')}
          </Text>
          <VStack
            width="100%"
            spacing={3}
            height="100%"
            justifyContent="space-between"
            flex="1"
          >
            <VStack width="100%" spacing={2} height="100%" alignItems="stretch">
              <VStack
                gap={0}
                borderRadius={1.5}
                boxShadow="detailsBlock"
                divider={<ThinDivider m="0 !important" />}
              >
                {senderAccount && (
                  <DetailsCard
                    icon={
                      <Icon
                        w={5}
                        h={5}
                        as={getCurrencyIcon(senderAccount.currency)}
                      />
                    }
                    title={formatAmountByCurrency(
                      senderAccount.amount,
                      senderAccount.currency,
                    )}
                    subtitle={senderAccount.number}
                    data-testid="card_sender-account"
                    borderBottomRadius={0}
                  />
                )}
                <ExchangeDropdownField
                  fieldName="receiverAccountId"
                  options={receiverAccountOptions}
                  triggerWrapperProps={{
                    borderTopRadius: 0,
                    boxShadow: 'none',
                    bgColor: 'secondary.500',
                  }}
                  optionLabelProps={{
                    textStyle: TextStyles.Subtitle14Medium,
                  }}
                  dropdownIcon={{
                    component: ChevronCircledIcon,
                    props: { h: '26px', w: '26px', color: 'primary.500' },
                  }}
                  onChange={(value) => onAccountChange(value as string)}
                  isDisabled={initialStep !== 'select'}
                />
              </VStack>
              <TransferWizardAmountField />
              <HStack justifyContent="space-between">
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.400"
                >
                  {t('accounts:transferMoney.selfTransfer')}
                </Text>
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.400"
                >
                  {isFetching
                    ? t('common:loading')
                    : t('accounts:transferMoney.transferFee', {
                        amount: fee?.feeAmount || 0,
                        currency: senderAccount?.currency.toUpperCase(),
                      })}
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <Button
          type="submit"
          form="selfTransferDetails"
          variant="primary"
          w="100%"
          data-testid="button_continue"
          isDisabled={!isEmpty(errors)}
        >
          {t('accounts:transferMoney.continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};
