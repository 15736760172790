export const onboarding = {
  rejectionPage: {
    title: 'We could not verify your profile',
    text1:
      'Unfortunately, the information you have provided does not pass the KYS verification requirements. The service is available to residents of the following list of countries:',
    text2:
      'We adhere to the AML with verification in terms of sanctions and political list.',
    text3:
      'If your application has been rejected in error, please contact our support team by email - oleh@spoynt.com. Thank you for your understanding and patience.',
    listTitle: 'List of allowed countries',
    buttonText: 'Go back to the website',
  },
  successPage: {
    successTitle: "You'll be redirected soon to your personal account",
  },
};
