import { HStack } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useAccountsBlockDisplaySettings } from './use-accounts-block-display-settings';
import { AccountCard } from './AccountCard';
import {
  URL_ACCOUNT_ID_KEY,
  useAccountIdFromUrl,
  useAccountsQuery,
  useSetAccountIdToUrl,
} from '../../hooks/accounts/queries';
import { getCurrencyIcon } from '../../currency';
import { AddAccountIcon, AllAccountsIcon } from '../../icons';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../hooks/use-permissions';
import { useCheckAppFlagEnabled } from '../../config/ConfigProvider';
import { ALL_CURRENCIES } from '@payler/bank-utils';
import { useShowAllAccountsDrawer } from '../AllAccountsDrawer/AllAccountsDrawer';
import { useShowAddAccountModal } from '../../modals/AddAccount/AddAccount';
import { deleteTransferIdsFromUrl } from '../../hooks/transfers/helpers';
import { useSearchParams } from 'react-router-dom';
import { TAccountDto } from '@payler/api/client-office';
import {
  useFirstAccountListWarning,
  useFirstAccountWarning,
} from '../../hooks/use-account-warning';

export const AccountsBlock = () => {
  const { data: accounts } = useAccountsQuery({
    enabled: true,
    isPollingEnabled: true,
  });
  const selectedAccountId = useAccountIdFromUrl();
  const setSelectedAccountId = useSetAccountIdToUrl();
  const { maxViewedAccounts, hasMore } = useAccountsBlockDisplaySettings();

  useEffect(() => {
    if (!selectedAccountId && accounts?.[0]) {
      setSelectedAccountId(accounts[0].id);
    }
  }, [accounts, selectedAccountId, setSelectedAccountId]);

  return (
    <HStack spacing={1} w="inherit">
      {accounts
        ?.slice(0, maxViewedAccounts)
        .map((account) => (
          <RealAccountCard
            key={account.id}
            account={account}
            selectedAccountId={selectedAccountId}
          />
        ))}
      {hasMore ? <AllAccountsCard /> : <AddAccountCard />}
    </HStack>
  );
};

const RealAccountCard = ({
  account,
  selectedAccountId,
}: {
  account: TAccountDto;
  selectedAccountId: string | null;
}) => {
  const [params, setParams] = useSearchParams();
  const { formatAmountByCurrency } = useLanguageFeatures();
  const { isGlobalAccounts } = usePermissions();
  const accountWarning = useFirstAccountWarning(account);

  const handleAccountCardClick = useCallback(() => {
    deleteTransferIdsFromUrl(params);
    params.set(URL_ACCOUNT_ID_KEY, account.id);
    setParams(params);
  }, [account.id, params, setParams]);

  return (
    <AccountCard
      caption={formatAmountByCurrency(account.amount, account.currency)}
      subCaption={account.name}
      icon={getCurrencyIcon(account.currency)}
      selected={account.id === selectedAccountId}
      onClick={handleAccountCardClick}
      isDisabled={!isGlobalAccounts}
      data-testid={`account-card_${
        account.id === selectedAccountId ? 'selected' : 'unselected'
      }`}
      overlayIcon={accountWarning?.icon}
      overlayIconColor={accountWarning?.iconColor}
    />
  );
};

const AllAccountsCard = () => {
  const { data: accounts } = useAccountsQuery();
  const [params, setParams] = useSearchParams();
  const { t } = useTranslation();
  const { isGlobalAccounts } = usePermissions();
  const open = useShowAllAccountsDrawer();
  const { hiddenNumberAccounts, maxViewedAccounts } =
    useAccountsBlockDisplaySettings();
  const accountWarning = useFirstAccountListWarning(accounts);

  const handleAllAccountsClick = () => {
    deleteTransferIdsFromUrl(params, setParams);
    open();
  };

  return (
    <AccountCard
      caption={t('accounts:allAccounts')}
      subCaption={t('accounts:hidden', { number: hiddenNumberAccounts })}
      icon={AllAccountsIcon}
      onClick={handleAllAccountsClick}
      isDisabled={!isGlobalAccounts}
      isHorizontal={maxViewedAccounts === 0}
      data-testid="show-all-accounts"
      withTooltip={false}
      color="brands.500"
      overlayIcon={accountWarning?.icon}
      overlayIconColor={accountWarning?.iconColor}
    />
  );
};

const AddAccountCard = () => {
  const { t } = useTranslation();
  const { isGlobalAccounts } = usePermissions();
  const isFeatureAddAccountsEnabled = useCheckAppFlagEnabled('accounts.add');
  const showAddAccountModal = useShowAddAccountModal();
  const { accountsCount } = useAccountsBlockDisplaySettings();

  if (!isFeatureAddAccountsEnabled) {
    return null;
  }

  return (
    <AccountCard
      caption={t('accounts:addAccount')}
      subCaption={t('accounts:currenciesNumber', {
        number: ALL_CURRENCIES.length,
      })}
      icon={AddAccountIcon}
      isHorizontal={accountsCount === 0}
      onClick={showAddAccountModal}
      isDisabled={!isGlobalAccounts}
      data-testid="add-accounts"
      withTooltip={false}
    />
  );
};
